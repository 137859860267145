@import 'bootstrap/dist/css/bootstrap.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import 'poppins.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #E5E5E5;
}
* {
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
.mina-toast {
  z-index: 9;
}
.mina-wrap {
  width: 100%;
  max-width: 1210px;
  padding: 0 15px;
}
.meainaIntrawebApp {
  position: relative;
  &:before {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    transition: .5s;
    background: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    content: " ";
    display: block;
  }
}
.mina-image-preview-thumb {
  height: 100px;
  object-fit: contain;
}
.cursor-pointer {
  cursor: pointer;
}
// form
.form-label {
  font-size: 14px;
  margin-bottom: 5px;
}
.form-control {
  border-color: rgba($color: #000000, $alpha: .5);
  box-shadow: unset;
  outline: unset;
  font-size: 12px;
  &:focus,&:active,&:hover,&:visited {
    box-shadow: unset;
    outline: unset;
    border-color: rgba($color: #000000, $alpha: .8);
  }
  .react-date-picker__inputGroup,.react-date-picker__wrapper {
    border: unset;
  }
}
// button
.btn {
  &.btn-primary {
    background: #29647B;
  }
  &:focus,&:active,&:hover,&:visited {
    box-shadow: unset;
    outline: unset;
  }
}
// header
header {
  width: 100%;
  position: fixed;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  height: 80px;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  top: 0;
  transition: .5s;
  .mina-logo {
    margin-right: 78px;
    padding: 8px 25px;
    transition: .5s;
    width: 100%;
    max-width: 169px;
  }
  .mina-navigation {
    transition: .5s;
    padding: 8px 15px;
    .mina-menu {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-right: auto;
      margin-left: 10px;
      li {
        margin-right: 42px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 16px;
          color: #5F6061;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
    .mina-account {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      img {
        height: 44px;
        width: 44px;
        border-radius: 100%;
        overflow: hidden;
      }
      span {
        font-size: 16px;
        color: #5F6061;
        font-weight: 600;
        margin-right: 8px;
      }
      .mina-account-dropdown {
        position: absolute;
        background: #fff;
        overflow: hidden;
        width: 330px;
        max-height: 0;
        transition: .5s;
        border-radius: 10px;
        top: 138%;
        right: 0;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        &.show {
          max-height: 500px;
        }
        > div {
          margin: 32px;
        }
        h6 {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 16px;
        }
        .form-group {
          margin-bottom: 10px;
          .control-label {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 5px;
          }
          .form-control {
            height: 28px;
            border-color: black;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 400;
          }
        }
        .mina-form-group-nav {
          margin-top: 20px;
          margin-bottom: unset;
          a {
            color: #29647B;
            font-size: 16px;
            font-weight: 400;
          }
          button {
            background: #29647B;
            height: 28px;
            border: unset;
            width: 95px;
            font-weight: 700;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.adminLayout {
  .meainaIntrawebApp {
    &:before {
      width: 280px;
    }
  }
  header{
    background: unset;
    box-shadow: unset;
    .mina-logo {
      margin-right: 35px;
      max-width: 280px;
      display: flex;
      justify-content: center;
    }
    .mina-navigation {
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      margin-right: 25px;
    }
  }
}
// content
.mina-content {
  position: relative;
  margin-top: 80px;
}
.mina-menu-left {
  min-width: 280px;
  max-width: 280px;
  margin-right: 35px;
  display: block;
  padding: 25px;
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  > div > h6 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 25px 0 15px;
  }
}
.mina-main-content {
  padding: 20px 0;
  padding-right: 25px;
  margin-left: 315px;
}
// menu
.mina-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    padding: 12px 0;
    a {
      font-size: 16px;
      font-weight: 400;
      color: black;
      img {
        height: 30px;
      }
    }
    span {
      cursor: pointer;
      position: relative;
      display: block;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.20);
        border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        display: block;
        position: absolute;
        right: 0;
        transform: rotate(45deg);
        transition: .5s;
      }
      &.active {
        &:after {
          transform: rotate(-135deg);
        }
      }
    }
    .mina-submenu {
      max-height: 0;
      overflow-y: hidden;
      transition: .5s;
      &.active {
        max-height: 500px;
        padding-top: 12px;
      }
      > li {
        padding: 6px 0;
        padding-left: 15px;
        border-bottom: 0;
        a {
          font-size: 14px;
        }
      }
    }
  }
}
.mina-menu-tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-right: 10px;
    margin-bottom: 10px;
    a {
      border-radius: 100px;
      padding: 5px 10px;
      font-size: 12px;
      background: rgba(229, 37, 42, 0.2);
      color: black;
    }
  }
}
// breadcumb
.mina-breadcumb {
  .mina-breadcumb-content {
    h1 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 600;
      padding-right: 12px;
      border-right: 1px solid #A1A1A1;
      margin-right: 12px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        position: relative;
        margin-right: 15px;
        a {
          font-size: 12px;
          color: #29647B;
          img {
            fill: #29647B;
            height: 18px;
          }
        }
        > img {
          margin-left: 15px;
        }
      }
    }
  }
}
// search form
.mina-search-form {
  position: fixed;
  width: 230px;
  .input-group {
    .form-control, .input-group-text {
      border-color: #A1A1A1;
      background-color: #fff;
    }
    .form-control {
      border-right: unset;
      color: black;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      &::placeholder {
        color: #A1A1A1;
      }
    }
    .input-group-text {
      border-left: unset;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}
// filesharing dashboard
.mina-filesharing-dashboard {
  .mina-filesharing-summary {
    .mina-filesharing-summary-item {
      background: rgba(2, 99, 209, 0.2);
      border-radius: 20px;
      padding: 20px 15px;
      height: 180px;
      &.item-sheet {
        background: rgba(0, 115, 59, 0.2);
      }
      &.item-pdf {
        background: rgba(229, 37, 42, 0.2);
      }
      &.item-persentation {
        background: rgba(255, 193, 7, 0.2);
      }
      > img {
        height: 50px;
      }
      > div {
        h6 {
          font-size: 16px;
          margin: 0;
          color: black;
        }
        span {
          font-size: 12px;
          color: rgba($color: #000000, $alpha: .5);
        }
      }
    }
  }
  .mina-filesharing-list {
    .table {
      thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        tr {
          border: unset;
          th {
            border: unset;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.3);
            font-weight: normal;
            padding: 10px;
          }
        }
      }
      tbody {
        border: unset;
        tr {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          td {
            vertical-align: middle;
            padding: 20px 10px;
            font-size: 14px;
            img {
              height: 24px;
            }
            a {
              color: black;
            }
          }
        }
      }
    }
  }
}
// table 
.mina-table {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  background: white;
  thead {
    tr {
      th {
        background: #29647B;
        color: white;
        font-size: 14px;
        font-weight: 700;
        border: unset !important;
      }
    }
  }
  tbody {
    border: unset !important;
    tr {
      border: unset !important;
      td {
        border: unset !important;
        padding: 15px 20px;
        a {
          color: black;
        }
      }
    }
  }
}
.mina-table-navbar,.mina-table-filter {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: white;
}
.mina-table-paging {
  .mtp-info {
    font-size: 16px;
    color: #5F6061;
  }
}
.mina-paging {
  .pagination {
    .page-item {
      &.page-item-prev,&.page-item-next {
        .page-link {
          background: #EDEDED;
          border-radius: 10px;
        }
      }
      &.page-item-prev {
        margin-right: 10px;
      }
      &.page-item-next {
        margin-left: 10px;
      }
      &.active {
        .page-link {
          background: #EDEDED;
        }
      }
      &:nth-child(2) {
        .page-link {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      &:nth-last-child(2) {
        .page-link {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .page-link {
        color: #5F6061;
        background: white;
        border: none;
      }
    }
  }
}
// page home
.mina-ph-carousel {
  .carousel {
    .carousel-inner {
      .carousel-item {
        img {
          width: 100%;
          height: 600px;
          object-fit: cover;
        }
        .carousel-caption {
          top: unset;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: unset;
          z-index: 2;
          background: #29647B;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          padding: 32px 25px;
          h1 {
            font-size: 40px;
            font-weight: 600;
            color: #fff;
            overflow: hidden;
          }
          p {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 15px;
          }
          a {
            width: 168px;
            height: 47px;
            background: #29647B;
            border-radius: 100px;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10%;
          }
        }
      }
    }
    .carousel-indicators {
      bottom: 32px;
      right: 25px;
      left: unset;
      margin-left: unset;
      margin-right: unset;
      margin-bottom: unset;
    }
  }
}
.mina-ph-news {
  padding: 50px 0;
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 2;
  > div {
    > h1 {
      font-size: 36px;
      font-weight: 700;
      color: #04111D;
      text-align: center;
      margin-bottom: 55px;
    }
  }
  .mina-ph-news-wrap {
    .mina-ph-news-item {
      border-radius: 40px;
      background: #fff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      > img {
        width: 100%;
        height: 220px;
        object-fit: cover;
      }
      .mina-ph-news-content {
        padding: 15px;
        text-align: center;
        h5 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 20px;
          color: #04111D;
          text-align: left;
        }
        label {
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 5px;
          color: #959595;
          text-align: right;
          width: 100%;
        }
        a {
          font-size: 16px;
          font-weight: 400;
          color: #29647B;
        }
      }
    }
  }
}
.mina-ph-file-sharing {
  padding: 30px 0;
  > div {
    > h1 {
      font-size: 36px;
      font-weight: 700;
      color: #04111D;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .mina-ph-file-sharing-wrap {
    .mina-ph-fsw-tab {
      margin-bottom: 50px;
      span {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: #5F6061;
        margin: 0 10px;
        padding: 3px 10px;
        &.active {
          background: #EDEDED;
        }
      }
    }
    .mina-ph-fsw-content {
      .mina-ph-fswc-item {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        background: #fff;
        padding: 15px 10px;
        margin-bottom: 20px;
        > img {
          margin-right: 15px;
        }
        .mina-ph-fswci-content {
          padding-right: 15px;
          h6 {
            font-size: 16px;
            font-weight: 400;
            color: #5F6061;
            margin-bottom: 10px;
          }
          label {
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 5px;
            color: #959595;
            text-align: right;
            width: 100%;
          }
        }
      }
    }
  }
  .mina-ph-fs-more {
    width: 168px;
    height: 47px;
    background: #29647B;
    border-radius: 100px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }
}
// news
.mina-news-item {
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  &.mina-news-details {
    > img {
      height: unset;
    }
  }
  .mina-news-item-content {
    padding: 20px;
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #04111D;
      margin-bottom: 15px;
    }
    .mina-news-ic-title {
      > img {
        height: 40px;
        width: 40px;
        border-radius: 100px;
      }
    }
  }
}
// footer
.mina-footer {
  padding: 15px;
  background: #EDEDED;
  span {
    font-size: 16px;
    font-weight: 400;
    color: #5F6061;
  }
}